import React from "react";

const pricingData = {
	sendgrid: {
		transactional: {
			1000: 19.95,
			10000: 19.95,
			100000: 34.95,
			250000: 249,
			500000: 499,
			1000000: 799,
		},
		marketing: {
			1000: 60,
			10000: 60,
			100000: 250,
			250000: 450,
			500000: 900,
			1000000: "Custom",
		},
	},
	sidemail: {
		all: {
			1000: 19,
			10000: 32,
			100000: 139,
			250000: 219,
			500000: 369,
			1000000: 519,
		},
	},
	mailgun: {
		transactional: {
			1000: 35,
			10000: 35,
			100000: 75,
			250000: 215,
			500000: 400,
			1000000: 700,
		},
		marketing: {
			1000: 27,
			10000: 27,
			100000: 105,
			250000: 250,
			500000: 470,
			1000000: "Custom",
		},
	},
	amazon: {
		all: {
			1000: 0.1,
			10000: 1,
			100000: 10,
			250000: 25,
			500000: 50,
			1000000: 100,
		},
	},
	postmark: {
		transactional: {
			1000: 15,
			10000: 15,
			100000: 127,
			250000: 270,
			500000: 546,
			1000000: 930,
		},
	},
	mailchimp: {
		transactional: {
			1000: 20,
			10000: 20,
			100000: 80,
			250000: 200,
			500000: 400,
			1000000: 720,
		},
		marketing: {
			1000: 20,
			10000: 100,
			100000: 410,
			250000: 1025,
			500000: 1600,
			1000000: "Custom",
		},
	},
	bird: {
		all: {
			1000: 75,
			10000: 75,
			100000: 75,
			250000: 170,
			500000: 290,
			1000000: 525,
		},
	},
	brevo: {
		all: {
			1000: 18,
			10000: 35,
			100000: 129,
			250000: 239,
			500000: 399,
			1000000: 669,
		},
	},
};

export default pricingData;
